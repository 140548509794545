// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.github-img {
  transition: width 2s;
  width: 30px;
}

.github-link {
  animation: fadeIn linear 1.5s;
}

.footer-container {
  position: fixed;
  bottom: 16px;
  left: 16px;
}

.github-img:hover {
  width: 50px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".github-img {\n  transition: width 2s;\n  width: 30px;\n}\n\n.github-link {\n  animation: fadeIn linear 1.5s;\n}\n\n.footer-container {\n  position: fixed;\n  bottom: 16px;\n  left: 16px;\n}\n\n.github-img:hover {\n  width: 50px;\n}\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
