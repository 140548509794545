// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'HelveticaNeue-Thin';
  background-color: #faf9f6;
  margin: 10000px 0 0;
}

#root {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

h1 {
  color: #000099;
  letter-spacing: 1px;
  font-family: 'Canela Deck Web, Palatino Linotype', serif;
  text-align: center;
}

h2 {
  color: #000099;
  font-weight: 200;
  font-family: 'HelveticaNeue';
}

h3,
h4,
h5,
h6 {
  font-weight: 100;
}

h2 {
  font-size: 32px;
}

.portfolioTitle,
.aboutTitle,
.contactTitle {
  font-size: 128px;
}

.about,
.homeContainer,
.portfolio-container,
.contact-container {
  margin-top: 96px;
}

@media only screen and (max-width: 390px) {
  .portfolioTitle,
  .aboutTitle,
  .contact-title {
    font-size: 72px;
  }
  .about,
  .homeContainer,
  .portfolio-container,
  .contact-container {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 769px) {
  .portfolioTitle,
  .aboutTitle,
  .contact-title {
    font-size: 96px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,SAAS;EACT,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,wDAAwD;EACxD,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;;;;EAIE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,gBAAgB;AAClB;;AAEA;;;;EAIE,gBAAgB;AAClB;;AAEA;EACE;;;IAGE,eAAe;EACjB;EACA;;;;IAIE,gBAAgB;EAClB;AACF;;AAEA;EACE;;;IAGE,eAAe;EACjB;AACF","sourcesContent":["body {\n  font-family: 'HelveticaNeue-Thin';\n  background-color: #faf9f6;\n  margin: 10000px 0 0;\n}\n\n#root {\n  min-height: 100vh;\n  margin: 0;\n  display: flex;\n  flex-direction: column;\n}\n\nh1 {\n  color: #000099;\n  letter-spacing: 1px;\n  font-family: 'Canela Deck Web, Palatino Linotype', serif;\n  text-align: center;\n}\n\nh2 {\n  color: #000099;\n  font-weight: 200;\n  font-family: 'HelveticaNeue';\n}\n\nh3,\nh4,\nh5,\nh6 {\n  font-weight: 100;\n}\n\nh2 {\n  font-size: 32px;\n}\n\n.portfolioTitle,\n.aboutTitle,\n.contactTitle {\n  font-size: 128px;\n}\n\n.about,\n.homeContainer,\n.portfolio-container,\n.contact-container {\n  margin-top: 96px;\n}\n\n@media only screen and (max-width: 390px) {\n  .portfolioTitle,\n  .aboutTitle,\n  .contact-title {\n    font-size: 72px;\n  }\n  .about,\n  .homeContainer,\n  .portfolio-container,\n  .contact-container {\n    margin-top: 40px;\n  }\n}\n\n@media only screen and (min-width: 390px) and (max-width: 769px) {\n  .portfolioTitle,\n  .aboutTitle,\n  .contact-title {\n    font-size: 96px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
