// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.banner {
  background-color: #000099;
  padding: 16px 0;
  color: #faf9f6;
  overflow: hidden;
  white-space: nowrap;
}

.banner-text {
  display: inline-block;
  animation: scrollText 30s linear infinite;
}

.banner-text span {
  margin-right: 40px;
  font-size: 32px;
}

@media (max-width: 390px) {
  .banner-text span {
    margin-right: 20px;
    font-size: 16px;
  }
}

@media (min-width: 391px) and (max-width: 769px) {
  .banner-text span {
    margin-right: 32px;
    font-size: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/SkillsBanner.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;IAClB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,eAAe;EACjB;AACF","sourcesContent":["@keyframes scrollText {\n  0% {\n    transform: translateX(0%);\n  }\n  100% {\n    transform: translateX(-50%);\n  }\n}\n\n.banner {\n  background-color: #000099;\n  padding: 16px 0;\n  color: #faf9f6;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.banner-text {\n  display: inline-block;\n  animation: scrollText 30s linear infinite;\n}\n\n.banner-text span {\n  margin-right: 40px;\n  font-size: 32px;\n}\n\n@media (max-width: 390px) {\n  .banner-text span {\n    margin-right: 20px;\n    font-size: 16px;\n  }\n}\n\n@media (min-width: 391px) and (max-width: 769px) {\n  .banner-text span {\n    margin-right: 32px;\n    font-size: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
