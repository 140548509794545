// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
}

.contact-title {
  font-size: 800%;
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 50%;
  animation: contactTitleLoad 500ms ease-in;
  line-height: 75%;
}

.contact-button {
  margin: 40px auto auto;
}

.contact-link {
  background-color: #000099;
  color: #faf9f6;
  width: 50%;
  padding: 8px 24px;
  margin: 0px;
  font-size: 20px;
  border: 0;
  border-radius: 24px 24px;
  text-decoration: none;
}

@keyframes contactTitleLoad {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes contactFormLoad {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 769px) {
  .contact-title {
    line-height: 75%;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Contact.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,UAAU;EACV,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,SAAS;EACT,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE;IACE,4BAA4B;EAC9B;;EAEA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,2BAA2B;EAC7B;;EAEA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,WAAW;EACb;AACF","sourcesContent":[".contact-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 24px;\n}\n\n.contact-title {\n  font-size: 800%;\n  font-style: italic;\n  margin-top: 0px;\n  margin-bottom: 0px;\n  width: 50%;\n  animation: contactTitleLoad 500ms ease-in;\n  line-height: 75%;\n}\n\n.contact-button {\n  margin: 40px auto auto;\n}\n\n.contact-link {\n  background-color: #000099;\n  color: #faf9f6;\n  width: 50%;\n  padding: 8px 24px;\n  margin: 0px;\n  font-size: 20px;\n  border: 0;\n  border-radius: 24px 24px;\n  text-decoration: none;\n}\n\n@keyframes contactTitleLoad {\n  0% {\n    transform: translateX(-100%);\n  }\n\n  100% {\n    transform: translateX(0);\n  }\n}\n\n@keyframes contactFormLoad {\n  0% {\n    transform: translateX(100%);\n  }\n\n  100% {\n    transform: translateX(0);\n  }\n}\n\n@media only screen and (max-width: 769px) {\n  .contact-title {\n    line-height: 75%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
