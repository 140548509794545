import React, { Component } from 'react';
import ContactDashboard from '../components/contactComponents/ContactDashboard';

export default class Contact extends Component {
  render() {
    return (
      <div>
        <ContactDashboard />
      </div>
    );
  }
}
